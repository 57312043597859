.investment-history {
    background-color: #000033;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .investment-history h2 {
    text-align: center;
    font-size: 24px;
  }
  
  .investment-list {
    display: flex;
    flex-direction: column;
  }
  
  .investment-item {
    padding: 15px;
    background-color: #001f3f;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .investment-details p {
    margin: 5px 0;
  }
  
  /* Responsive for small screens */
  @media (max-width: 768px) {
    .investment-item {
      padding: 10px;
    }
  
    .investment-history h2 {
      font-size: 20px;
    }
  }
  


  .wallet-section {
    background-color: #000033;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .wallet-info h2 {
    font-size: 22px;
    margin-bottom: 10px;
  }
  
  .user-info h3 {
    font-size: 18px;
    margin-top: 10px;
  }
  
  /* Responsive for small screens */
  @media (max-width: 768px) {
    .wallet-info h2 {
      font-size: 18px;
    }
  
    .user-info h3 {
      font-size: 16px;
    }
  }

  
  .profile-card {
    background-color: #001f3f;
    color: #fff;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .profile-header {
    margin-bottom: 20px;
  }
  
  .profile-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  .profile-header h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .profile-email {
    font-size: 14px;
    color: #ccc;
  }
  
  .profile-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .detail-item {
    background-color: #000033;
    padding: 10px;
    border-radius: 8px;
  }
  
  .detail-item h3 {
    margin-bottom: 5px;
    font-size: 18px;
  }
  
  .detail-item p {
    margin: 0;
    font-size: 16px;
    color: #b3d4fc;
  }
  
  /* Responsive for small screens */
  @media (max-width: 768px) {
    .profile-card {
      padding: 15px;
    }
  
    .profile-header h2 {
      font-size: 20px;
    }
  
    .detail-item h3 {
      font-size: 16px;
    }
  
    .detail-item p {
      font-size: 14px;
    }
  }
  

.digital-trade {
    background-color: #000033;
    color: #FFF;
    font-family: 'Arial', sans-serif;
    text-align: center;
  }
  
  .wallet-section {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color: #fff;
  }
  
  .wallet-info h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #fff;
  }
  
  .wallet-info p {
    margin: 0;
    font-size: 1rem;
    color: #fff;
  }
  
  .wallet-options {
    display: flex;
    align-items: center;
    color: #fff;
  }
  
  .binance-logo {
    width: 40px;
    height: 40px;
  }
  
  .settings-icon {
    font-size: 24px;
    margin-left: 10px;
    color: #fff;
  }
  
  .cards-section-container{
    color: #fff;
    background-color: #000033;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    border-top: 5px solid #f0e00f;
    box-shadow: inset;
  }
  
  .cards-section {
    display: flex;
    color: #fff;
    justify-content: space-around;
    margin: 20px 0;
  }
  
  .card {
    background-color: #000033;
    color: #fff;
    padding: 5px;
    border-radius: 10px;
    width: 35%;
    margin: 5px;
    border: 2px solid #f0f0f0;
  }
  
  .card h3{
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #fff;
  }
  
  .cards-section .card, h3, p{
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #fff;
  }
  
  .deposit-btn {
    background-color: #f0e00f;
    color: #000033;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }
  
  .mascot-section {
    margin: 20px 0;
    background-color: #000033;
    /* border: 2px solid #f0e00f; */
    border-radius: 25px;
  }
  
  .mascot-img {
    width: 150px;
  }
  
  .bottom-nav {
    display: flex;
    justify-content: space-around;
    background-color: #1f1f3f;
    padding: 10px;
  }
  
  .nav-item {
    color: #FFF;
    cursor: pointer;
  }
  